export const numberExponentToLarge = (
  numInPassed: number,
  tofixed = 9
): string => {
  let numIn = String(numInPassed.toFixed(tofixed));
  var sign = "";
  numIn.charAt(0) == "-" && ((numIn = numIn.substring(1)), (sign = "-"));
  var str = numIn.split(/[eE]/g);
  if (str.length < 2) return sign + numIn;
  var power = str[1];
  if (Number(power) === 0) return sign + str[0];

  var deciSp = (1.1).toLocaleString().substring(1, 2);
  str = str[0].split(deciSp);
  var baseRH = str[1] || "",
    baseLH = str[0];

  if (Number(power) > 0) {
    if (Number(power) > baseRH.length)
      baseRH += "0".repeat(Number(power) - baseRH.length);
    baseRH =
      baseRH.slice(0, Number(power)) + deciSp + baseRH.slice(Number(power));
    if (baseRH.charAt(baseRH.length - 1) == deciSp)
      baseRH = baseRH.slice(0, -1);
  } else {
    let num = Math.abs(Number(power)) - baseLH.length;
    if (num > 0) baseLH = "0".repeat(num) + baseLH;
    baseLH =
      baseLH.slice(0, Number(power)) + deciSp + baseLH.slice(Number(power));
    if (baseLH.charAt(0) == deciSp) baseLH = "0" + baseLH;
  }
  return sign + baseLH + baseRH;
};

export const formatNumberWithSpaces = (numberString: string | number) => {
  let parts = String(numberString).split(".");
  let integerPart = parts[0];
  let decimalPart = parts.length > 1 ? "." + parts[1] : "";

  let reversedString = integerPart.split("").reverse().join("");
  let formattedString = "";

  for (let i = 0; i < reversedString.length; i++) {
    if (i > 0 && i % 3 === 0) {
      formattedString += " ";
    }
    formattedString += reversedString[i];
  }

  return formattedString.split("").reverse().join("") + decimalPart;
};

export const inputIsNumber = ($event: any) => {
  const evt = $event ? $event : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
    evt.preventDefault();
  } else {
    return true;
  }
};
